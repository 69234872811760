
import "index.scss"

import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faLinkedin, faDribbbleSquare
} from '@fortawesome/free-brands-svg-icons'
library.add(faLinkedin, faDribbbleSquare)

import {
  faGlobe
} from '@fortawesome/free-solid-svg-icons'
library.add(faGlobe)

dom.watch()

// Import all javascript files from src/_components
const componentsContext = require.context("bridgetownComponents", true, /.js$/)
componentsContext.keys().forEach(componentsContext)

console.info("Bridgetown is loaded!")
